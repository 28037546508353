/* tslint:disable */
/* eslint-disable */
/**
 * Smart Central - FDC
 * Smart Central - FDC API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface AttSignPayloadAllOf
 */
export interface AttSignPayloadAllOf {
    /**
     * 
     * @type {string}
     * @memberof AttSignPayloadAllOf
     */
    'in'?: AttSignPayloadAllOfInEnum;
    /**
     * 
     * @type {string}
     * @memberof AttSignPayloadAllOf
     */
    'out'?: AttSignPayloadAllOfOutEnum;
}

export const AttSignPayloadAllOfInEnum = {
    In: 'in',
    CancelIn: 'cancel_in'
} as const;

export type AttSignPayloadAllOfInEnum = typeof AttSignPayloadAllOfInEnum[keyof typeof AttSignPayloadAllOfInEnum];
export const AttSignPayloadAllOfOutEnum = {
    Out: 'out',
    CancelOut: 'cancel_out'
} as const;

export type AttSignPayloadAllOfOutEnum = typeof AttSignPayloadAllOfOutEnum[keyof typeof AttSignPayloadAllOfOutEnum];


