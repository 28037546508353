/* tslint:disable */
/* eslint-disable */
/**
 * Smart Central - FDC
 * Smart Central - FDC API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DeleteSessionsPayload } from '../models';
// @ts-ignore
import { NewSessionPayload } from '../models';
// @ts-ignore
import { Room } from '../models';
// @ts-ignore
import { SessionCode } from '../models';
/**
 * SessionRecordApi - axios parameter creator
 * @export
 */
export const SessionRecordApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete all sessions
         * @param {number} srvId Service ID
         * @param {DeleteSessionsPayload} [deleteSessionsPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSessions: async (srvId: number, deleteSessionsPayload?: DeleteSessionsPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'srvId' is not null or undefined
            assertParamExists('deleteSessions', 'srvId', srvId)
            const localVarPath = `/service/{srv_id}/daily_session/delete_all.json`
                .replace(`{${"srv_id"}}`, encodeURIComponent(String(srvId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteSessionsPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get rooms information
         * @param {number} srvId Service ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRooms: async (srvId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'srvId' is not null or undefined
            assertParamExists('getRooms', 'srvId', srvId)
            const localVarPath = `/service/{srv_id}/room.json`
                .replace(`{${"srv_id"}}`, encodeURIComponent(String(srvId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get session codes for service
         * @param {number} orgId Organisation ID
         * @param {number} srvId Service ID
         * @param {string} date Date today
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSessionCodes: async (orgId: number, srvId: number, date: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgId' is not null or undefined
            assertParamExists('getSessionCodes', 'orgId', orgId)
            // verify required parameter 'srvId' is not null or undefined
            assertParamExists('getSessionCodes', 'srvId', srvId)
            // verify required parameter 'date' is not null or undefined
            assertParamExists('getSessionCodes', 'date', date)
            const localVarPath = `/organisation/{org_id}/service/session_codes_by_date/{srv_id}.json`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"srv_id"}}`, encodeURIComponent(String(srvId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add session
         * @param {number} srvId Service ID
         * @param {NewSessionPayload} [newSessionPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAddSession: async (srvId: number, newSessionPayload?: NewSessionPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'srvId' is not null or undefined
            assertParamExists('postAddSession', 'srvId', srvId)
            const localVarPath = `/service/{srv_id}/daily_session/create.json`
                .replace(`{${"srv_id"}}`, encodeURIComponent(String(srvId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newSessionPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SessionRecordApi - functional programming interface
 * @export
 */
export const SessionRecordApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SessionRecordApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete all sessions
         * @param {number} srvId Service ID
         * @param {DeleteSessionsPayload} [deleteSessionsPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSessions(srvId: number, deleteSessionsPayload?: DeleteSessionsPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSessions(srvId, deleteSessionsPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get rooms information
         * @param {number} srvId Service ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRooms(srvId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Room>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRooms(srvId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get session codes for service
         * @param {number} orgId Organisation ID
         * @param {number} srvId Service ID
         * @param {string} date Date today
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSessionCodes(orgId: number, srvId: number, date: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SessionCode>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSessionCodes(orgId, srvId, date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add session
         * @param {number} srvId Service ID
         * @param {NewSessionPayload} [newSessionPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAddSession(srvId: number, newSessionPayload?: NewSessionPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAddSession(srvId, newSessionPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SessionRecordApi - factory interface
 * @export
 */
export const SessionRecordApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SessionRecordApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete all sessions
         * @param {number} srvId Service ID
         * @param {DeleteSessionsPayload} [deleteSessionsPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSessions(srvId: number, deleteSessionsPayload?: DeleteSessionsPayload, options?: any): AxiosPromise<void> {
            return localVarFp.deleteSessions(srvId, deleteSessionsPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get rooms information
         * @param {number} srvId Service ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRooms(srvId: number, options?: any): AxiosPromise<Array<Room>> {
            return localVarFp.getRooms(srvId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get session codes for service
         * @param {number} orgId Organisation ID
         * @param {number} srvId Service ID
         * @param {string} date Date today
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSessionCodes(orgId: number, srvId: number, date: string, options?: any): AxiosPromise<Array<SessionCode>> {
            return localVarFp.getSessionCodes(orgId, srvId, date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add session
         * @param {number} srvId Service ID
         * @param {NewSessionPayload} [newSessionPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAddSession(srvId: number, newSessionPayload?: NewSessionPayload, options?: any): AxiosPromise<void> {
            return localVarFp.postAddSession(srvId, newSessionPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SessionRecordApi - object-oriented interface
 * @export
 * @class SessionRecordApi
 * @extends {BaseAPI}
 */
export class SessionRecordApi extends BaseAPI {
    /**
     * 
     * @summary Delete all sessions
     * @param {number} srvId Service ID
     * @param {DeleteSessionsPayload} [deleteSessionsPayload] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionRecordApi
     */
    public deleteSessions(srvId: number, deleteSessionsPayload?: DeleteSessionsPayload, options?: AxiosRequestConfig) {
        return SessionRecordApiFp(this.configuration).deleteSessions(srvId, deleteSessionsPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get rooms information
     * @param {number} srvId Service ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionRecordApi
     */
    public getRooms(srvId: number, options?: AxiosRequestConfig) {
        return SessionRecordApiFp(this.configuration).getRooms(srvId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get session codes for service
     * @param {number} orgId Organisation ID
     * @param {number} srvId Service ID
     * @param {string} date Date today
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionRecordApi
     */
    public getSessionCodes(orgId: number, srvId: number, date: string, options?: AxiosRequestConfig) {
        return SessionRecordApiFp(this.configuration).getSessionCodes(orgId, srvId, date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add session
     * @param {number} srvId Service ID
     * @param {NewSessionPayload} [newSessionPayload] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionRecordApi
     */
    public postAddSession(srvId: number, newSessionPayload?: NewSessionPayload, options?: AxiosRequestConfig) {
        return SessionRecordApiFp(this.configuration).postAddSession(srvId, newSessionPayload, options).then((request) => request(this.axios, this.basePath));
    }
}
