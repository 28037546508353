import { useQuery } from '@tanstack/react-query'
import { emitError } from 'common/utils/event-emitter'
import { useGqlController } from 'common/hooks/use-gql-controller'
import { getSdk } from 'generated/graphql/schema'
import { ClientError } from 'graphql-request'
import { useMemo } from 'react'

export const useStaffController = (roles?: string[]) => {
  const { gqlClient, isReady, query } = useGqlController()
  const { srvid } = query

  const { getEducators } = getSdk(gqlClient)

  const { data: dataStaff, isLoading: isStaffLoading } = useQuery(
    ['fetch-educators', srvid],
    () => getEducators({ srvId: srvid as string }),
    {
      enabled: isReady && !!srvid && !!roles?.includes('admin'),
      onError: (err: ClientError) => {
        emitError(`Error fetching educators. ${err.response.error}`)
      },
    }
  )

  const staffList = useMemo(() => {
    return dataStaff?.service.educators?.filter(
      (educator, index, educators) =>
        educators.findIndex(({ ccsUid }) => educator.ccsUid === ccsUid) ===
        index
    )
  }, [dataStaff])

  return {
    isLoading: isStaffLoading || !isReady,
    staffList,
  }
}
