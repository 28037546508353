/* tslint:disable */
/* eslint-disable */
/**
 * Smart Central - FDC
 * Smart Central - FDC API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface Job
 */
export interface Job {
    /**
     * 
     * @type {number}
     * @memberof Job
     */
    'org_id': number;
    /**
     * 
     * @type {number}
     * @memberof Job
     */
    'service_id': number;
    /**
     * 
     * @type {string}
     * @memberof Job
     */
    's_name': string;
    /**
     * 
     * @type {string}
     * @memberof Job
     */
    'fdc_educator_id': string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof Job
     */
    'roles': Array<JobRolesEnum>;
}

export const JobRolesEnum = {
    Fdc: 'fdc',
    Admin: 'admin'
} as const;

export type JobRolesEnum = typeof JobRolesEnum[keyof typeof JobRolesEnum];


