import axios from 'axios'
import { useQuery } from '@tanstack/react-query'
import { useRestController } from 'common/hooks/use-rest-controller'
import { UserApiFactory } from 'generated/restapi'
import { emitError } from 'common/utils/event-emitter'

export const useUserInfoController = () => {
  const { config, isReady } = useRestController()

  const { getUserInfo } = UserApiFactory(config)

  const { data: dataUserInfo, isLoading: isUserInfoLoading } = useQuery(
    ['fetch-user-info'],
    () => getUserInfo(),
    {
      enabled: isReady,
      onError: (err) => {
        let errMessage = err
        if (axios.isAxiosError(err) && err.response) {
          errMessage = err.response.statusText
        }
        emitError(`Error fetching user pin. ${errMessage}`)
      },
    }
  )

  return { userInfo: dataUserInfo?.data, isLoading: isUserInfoLoading }
}
