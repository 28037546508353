/* tslint:disable */
/* eslint-disable */
/**
 * Smart Central - FDC
 * Smart Central - FDC API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { PostVisitorSign200Response } from '../models';
// @ts-ignore
import { PostVisitorSignRequest } from '../models';
// @ts-ignore
import { Visitor } from '../models';
/**
 * VisitorApi - axios parameter creator
 * @export
 */
export const VisitorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get visitors list
         * @param {number} orgId Organisation ID
         * @param {number} service Service ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVisitors: async (orgId: number, service: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgId' is not null or undefined
            assertParamExists('getVisitors', 'orgId', orgId)
            // verify required parameter 'service' is not null or undefined
            assertParamExists('getVisitors', 'service', service)
            const localVarPath = `/organisation/visitors/{org_id}.json`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (service !== undefined) {
                localVarQueryParameter['service'] = service;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Visitor Sign In/Out
         * @param {PostVisitorSignRequest} postVisitorSignRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postVisitorSign: async (postVisitorSignRequest: PostVisitorSignRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'postVisitorSignRequest' is not null or undefined
            assertParamExists('postVisitorSign', 'postVisitorSignRequest', postVisitorSignRequest)
            const localVarPath = `/user/visitor_signin.json`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postVisitorSignRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VisitorApi - functional programming interface
 * @export
 */
export const VisitorApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VisitorApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get visitors list
         * @param {number} orgId Organisation ID
         * @param {number} service Service ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVisitors(orgId: number, service: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Visitor>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVisitors(orgId, service, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Visitor Sign In/Out
         * @param {PostVisitorSignRequest} postVisitorSignRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postVisitorSign(postVisitorSignRequest: PostVisitorSignRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostVisitorSign200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postVisitorSign(postVisitorSignRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VisitorApi - factory interface
 * @export
 */
export const VisitorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VisitorApiFp(configuration)
    return {
        /**
         * 
         * @summary Get visitors list
         * @param {number} orgId Organisation ID
         * @param {number} service Service ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVisitors(orgId: number, service: number, options?: any): AxiosPromise<Array<Visitor>> {
            return localVarFp.getVisitors(orgId, service, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Visitor Sign In/Out
         * @param {PostVisitorSignRequest} postVisitorSignRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postVisitorSign(postVisitorSignRequest: PostVisitorSignRequest, options?: any): AxiosPromise<PostVisitorSign200Response> {
            return localVarFp.postVisitorSign(postVisitorSignRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VisitorApi - object-oriented interface
 * @export
 * @class VisitorApi
 * @extends {BaseAPI}
 */
export class VisitorApi extends BaseAPI {
    /**
     * 
     * @summary Get visitors list
     * @param {number} orgId Organisation ID
     * @param {number} service Service ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisitorApi
     */
    public getVisitors(orgId: number, service: number, options?: AxiosRequestConfig) {
        return VisitorApiFp(this.configuration).getVisitors(orgId, service, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Visitor Sign In/Out
     * @param {PostVisitorSignRequest} postVisitorSignRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisitorApi
     */
    public postVisitorSign(postVisitorSignRequest: PostVisitorSignRequest, options?: AxiosRequestConfig) {
        return VisitorApiFp(this.configuration).postVisitorSign(postVisitorSignRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
