/* tslint:disable */
/* eslint-disable */
/**
 * Smart Central - FDC
 * Smart Central - FDC API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BookingCreate } from '../models';
// @ts-ignore
import { BookingResponse } from '../models';
// @ts-ignore
import { ResponseOk } from '../models';
/**
 * BookingApi - axios parameter creator
 * @export
 */
export const BookingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a casual booking
         * @param {number} srvId Service ID
         * @param {BookingCreate} bookingCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCreateBooking: async (srvId: number, bookingCreate: BookingCreate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'srvId' is not null or undefined
            assertParamExists('postCreateBooking', 'srvId', srvId)
            // verify required parameter 'bookingCreate' is not null or undefined
            assertParamExists('postCreateBooking', 'bookingCreate', bookingCreate)
            const localVarPath = `/service/{srv_id}/daily_session/booking`
                .replace(`{${"srv_id"}}`, encodeURIComponent(String(srvId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bookingCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a casual booking
         * @param {number} enrolmentId Enrolment ID
         * @param {number} bookingId Booking ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDeleteCasualBooking: async (enrolmentId: number, bookingId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'enrolmentId' is not null or undefined
            assertParamExists('postDeleteCasualBooking', 'enrolmentId', enrolmentId)
            // verify required parameter 'bookingId' is not null or undefined
            assertParamExists('postDeleteCasualBooking', 'bookingId', bookingId)
            const localVarPath = `/enrolment/{enrolment_id}/booking/delete_casual/{booking_id}.json`
                .replace(`{${"enrolment_id"}}`, encodeURIComponent(String(enrolmentId)))
                .replace(`{${"booking_id"}}`, encodeURIComponent(String(bookingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BookingApi - functional programming interface
 * @export
 */
export const BookingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BookingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a casual booking
         * @param {number} srvId Service ID
         * @param {BookingCreate} bookingCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCreateBooking(srvId: number, bookingCreate: BookingCreate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCreateBooking(srvId, bookingCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a casual booking
         * @param {number} enrolmentId Enrolment ID
         * @param {number} bookingId Booking ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postDeleteCasualBooking(enrolmentId: number, bookingId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseOk>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postDeleteCasualBooking(enrolmentId, bookingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BookingApi - factory interface
 * @export
 */
export const BookingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BookingApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a casual booking
         * @param {number} srvId Service ID
         * @param {BookingCreate} bookingCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCreateBooking(srvId: number, bookingCreate: BookingCreate, options?: any): AxiosPromise<BookingResponse> {
            return localVarFp.postCreateBooking(srvId, bookingCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a casual booking
         * @param {number} enrolmentId Enrolment ID
         * @param {number} bookingId Booking ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDeleteCasualBooking(enrolmentId: number, bookingId: number, options?: any): AxiosPromise<ResponseOk> {
            return localVarFp.postDeleteCasualBooking(enrolmentId, bookingId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BookingApi - object-oriented interface
 * @export
 * @class BookingApi
 * @extends {BaseAPI}
 */
export class BookingApi extends BaseAPI {
    /**
     * 
     * @summary Create a casual booking
     * @param {number} srvId Service ID
     * @param {BookingCreate} bookingCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingApi
     */
    public postCreateBooking(srvId: number, bookingCreate: BookingCreate, options?: AxiosRequestConfig) {
        return BookingApiFp(this.configuration).postCreateBooking(srvId, bookingCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a casual booking
     * @param {number} enrolmentId Enrolment ID
     * @param {number} bookingId Booking ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingApi
     */
    public postDeleteCasualBooking(enrolmentId: number, bookingId: number, options?: AxiosRequestConfig) {
        return BookingApiFp(this.configuration).postDeleteCasualBooking(enrolmentId, bookingId, options).then((request) => request(this.axios, this.basePath));
    }
}
