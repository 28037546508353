import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useRestController } from 'common/hooks/use-rest-controller'
import { UserApiFactory } from 'generated/restapi/api'
import { emitError } from 'common/utils/event-emitter'

export const useJobsController = () => {
  const { config, push, isReady } = useRestController()
  const { getJobs } = UserApiFactory(config)

  const { data: dataJobs, isLoading: isJobsLoading } = useQuery(
    ['fetch-jobs'],
    () => getJobs(),
    {
      enabled: isReady,
      onError: () => {
        emitError(`Error fetching jobs info`)
      },
    }
  )

  const jobs = useMemo(() => {
    if (dataJobs && dataJobs.data.jobs) {
      return dataJobs.data.jobs
        .filter((job, index, jobs) => jobs.indexOf(job) === index)
        .sort((a, b) => (a.s_name > b.s_name ? 1 : -1))
    }
  }, [dataJobs])

  const rooms = useMemo(() => {
    return dataJobs?.data.rooms
  }, [dataJobs])

  const handleOnContinue = (orgid: string, srvid: string) => {
    push(`/org/${orgid}/srv/${srvid}`)
  }

  return {
    isLoading: isJobsLoading,
    jobs,
    rooms,
    handleOnContinue,
  }
}

export type TJobsController = ReturnType<typeof useJobsController>
