import { useRouter } from 'next/router'
import { createContext, useContext, ReactNode, useMemo, useState, Dispatch, useEffect } from 'react'
import { useJobsController } from 'modules/jobs/hooks/use-jobs-controller'
import { useStaffController } from 'modules/staff/hooks/use-staff-controller'
import { useUserInfoController } from 'modules/settings/hooks/use-user-info-controller'
import { Job, Room, UserInfo } from 'generated/restapi'

interface IFdcEducator {
  fdcEducatorId: string
  name: string
  email: string
}

interface IAppContextProps {
  userInfo?: UserInfo
  job?: Job
  room?: Room
  staffInfo?: IFdcEducator[]
  selectedEducator?: IFdcEducator
  setSelectedEducator: Dispatch<IFdcEducator>
}

interface IAppContextProviderProps {
  children: ReactNode
}

const appContext = createContext({} as IAppContextProps)

export const AppContext = (props: IAppContextProviderProps) => {
  const { orgid, srvid } = useRouter().query
  const { userInfo } = useUserInfoController()
  const [selectedEducator, setSelectedEducator] = useState<IFdcEducator>()

  const { jobs, rooms } = useJobsController()
  const job = useMemo(() => {
    return jobs?.find((job) => job.org_id === parseInt(orgid as string) && job.service_id === parseInt(srvid as string))
  }, [jobs, orgid, srvid])

  const { staffList } = useStaffController(job?.roles)
  const staffInfo: IFdcEducator[] | undefined = useMemo(() => {
    return staffList
      ?.map((staff) => ({
        fdcEducatorId: staff.ccsUid,
        name: `${staff.firstName} ${staff.lastName}`,
        email: `${staff.email}`,
      }))
      .filter(({ fdcEducatorId }) => !!fdcEducatorId)
  }, [staffList])

  useEffect(() => {
    if (job?.roles.includes('admin') && staffInfo && !selectedEducator) {
      setSelectedEducator(staffInfo.at(0))
    } else if (job?.roles.includes('fdc') && !selectedEducator && job.fdc_educator_id) {
      setSelectedEducator({
        fdcEducatorId: job.fdc_educator_id,
        name: `${userInfo?.first_name} ${userInfo?.last_name}`,
        email: `${userInfo?.email}`,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [job, staffInfo])

  const room = useMemo(() => {
    return rooms?.find(
      (room) =>
        room.fdc_educator_id === selectedEducator?.fdcEducatorId && room.service_id === parseInt(srvid as string)
    )
  }, [rooms, srvid, selectedEducator])

  return (
    <appContext.Provider
      value={{
        userInfo,
        job,
        room,
        staffInfo,
        selectedEducator,
        setSelectedEducator,
      }}
    >
      {props.children}
    </appContext.Provider>
  )
}

export const useAppContext = () => useContext(appContext)
