/* tslint:disable */
/* eslint-disable */
/**
 * Smart Central - FDC
 * Smart Central - FDC API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DeleteSessionMiscFee200Response } from '../models';
// @ts-ignore
import { JobsInfo } from '../models';
// @ts-ignore
import { Statement } from '../models';
// @ts-ignore
import { UserInfo } from '../models';
// @ts-ignore
import { UserPin } from '../models';
/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get user job info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJobs: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/jobs.json`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user financial statements
         * @param {number} orgId Organisation ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatements: async (orgId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgId' is not null or undefined
            assertParamExists('getStatements', 'orgId', orgId)
            const localVarPath = `/user/fdc_stmts/{org_id}.json`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get current user info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserInfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user pin
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPin: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/pin.json`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates user password
         * @param {string} existingPassword Existing password
         * @param {string} newPassword New password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUpdatePassword: async (existingPassword: string, newPassword: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'existingPassword' is not null or undefined
            assertParamExists('postUpdatePassword', 'existingPassword', existingPassword)
            // verify required parameter 'newPassword' is not null or undefined
            assertParamExists('postUpdatePassword', 'newPassword', newPassword)
            const localVarPath = `/user/update_password.json`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (existingPassword !== undefined) {
                localVarQueryParameter['existing_password'] = existingPassword;
            }

            if (newPassword !== undefined) {
                localVarQueryParameter['new_password'] = newPassword;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get user job info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getJobs(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JobsInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getJobs(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get user financial statements
         * @param {number} orgId Organisation ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStatements(orgId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Statement>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStatements(orgId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get current user info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserInfo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get user pin
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserPin(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserPin>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserPin(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates user password
         * @param {string} existingPassword Existing password
         * @param {string} newPassword New password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postUpdatePassword(existingPassword: string, newPassword: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteSessionMiscFee200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postUpdatePassword(existingPassword, newPassword, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @summary Get user job info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJobs(options?: any): AxiosPromise<JobsInfo> {
            return localVarFp.getJobs(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user financial statements
         * @param {number} orgId Organisation ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatements(orgId: number, options?: any): AxiosPromise<Array<Statement>> {
            return localVarFp.getStatements(orgId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get current user info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserInfo(options?: any): AxiosPromise<UserInfo> {
            return localVarFp.getUserInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user pin
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPin(options?: any): AxiosPromise<UserPin> {
            return localVarFp.getUserPin(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates user password
         * @param {string} existingPassword Existing password
         * @param {string} newPassword New password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUpdatePassword(existingPassword: string, newPassword: string, options?: any): AxiosPromise<DeleteSessionMiscFee200Response> {
            return localVarFp.postUpdatePassword(existingPassword, newPassword, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @summary Get user job info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getJobs(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getJobs(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get user financial statements
     * @param {number} orgId Organisation ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getStatements(orgId: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getStatements(orgId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get current user info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUserInfo(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getUserInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get user pin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUserPin(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getUserPin(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates user password
     * @param {string} existingPassword Existing password
     * @param {string} newPassword New password
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public postUpdatePassword(existingPassword: string, newPassword: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).postUpdatePassword(existingPassword, newPassword, options).then((request) => request(this.axios, this.basePath));
    }
}
