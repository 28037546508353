/* tslint:disable */
/* eslint-disable */
/**
 * Smart Central - FDC
 * Smart Central - FDC API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AttSignPayload } from '../models';
// @ts-ignore
import { Attendance } from '../models';
// @ts-ignore
import { DailySession } from '../models';
// @ts-ignore
import { Session } from '../models';
// @ts-ignore
import { SessionsHeadcountPayload } from '../models';
// @ts-ignore
import { SessionsHeadcountResponse } from '../models';
/**
 * AttendanceApi - axios parameter creator
 * @export
 */
export const AttendanceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get daily session attendance record
         * @param {number} srvId Service ID
         * @param {string} [date] Record date
         * @param {string} [fdcEducatorId] FDC educator ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAttRecord: async (srvId: number, date?: string, fdcEducatorId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'srvId' is not null or undefined
            assertParamExists('getAttRecord', 'srvId', srvId)
            const localVarPath = `/service/{srv_id}/daily_session.json`
                .replace(`{${"srv_id"}}`, encodeURIComponent(String(srvId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (fdcEducatorId !== undefined) {
                localVarQueryParameter['fdc_educator_id'] = fdcEducatorId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get end of week attendance report
         * @param {number} srvId Service ID
         * @param {string} [date] Record date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAttendances: async (srvId: number, date?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'srvId' is not null or undefined
            assertParamExists('getAttendances', 'srvId', srvId)
            const localVarPath = `/service/{srv_id}/attendance/list.json`
                .replace(`{${"srv_id"}}`, encodeURIComponent(String(srvId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Signing in/out or absent for a child session
         * @param {number} srvId Service ID
         * @param {number} sessionId Session ID
         * @param {AttSignPayload} attSignPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAttSign: async (srvId: number, sessionId: number, attSignPayload: AttSignPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'srvId' is not null or undefined
            assertParamExists('postAttSign', 'srvId', srvId)
            // verify required parameter 'sessionId' is not null or undefined
            assertParamExists('postAttSign', 'sessionId', sessionId)
            // verify required parameter 'attSignPayload' is not null or undefined
            assertParamExists('postAttSign', 'attSignPayload', attSignPayload)
            const localVarPath = `/service/{srv_id}/daily_session/update/{session_id}.json`
                .replace(`{${"srv_id"}}`, encodeURIComponent(String(srvId)))
                .replace(`{${"session_id"}}`, encodeURIComponent(String(sessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(attSignPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Submit attendance headcount
         * @param {number} srvId Service ID
         * @param {SessionsHeadcountPayload} sessionsHeadcountPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postHeadcount: async (srvId: number, sessionsHeadcountPayload: SessionsHeadcountPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'srvId' is not null or undefined
            assertParamExists('postHeadcount', 'srvId', srvId)
            // verify required parameter 'sessionsHeadcountPayload' is not null or undefined
            assertParamExists('postHeadcount', 'sessionsHeadcountPayload', sessionsHeadcountPayload)
            const localVarPath = `/service/{srv_id}/daily_session/record_head_count.json`
                .replace(`{${"srv_id"}}`, encodeURIComponent(String(srvId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sessionsHeadcountPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AttendanceApi - functional programming interface
 * @export
 */
export const AttendanceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AttendanceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get daily session attendance record
         * @param {number} srvId Service ID
         * @param {string} [date] Record date
         * @param {string} [fdcEducatorId] FDC educator ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAttRecord(srvId: number, date?: string, fdcEducatorId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DailySession>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAttRecord(srvId, date, fdcEducatorId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get end of week attendance report
         * @param {number} srvId Service ID
         * @param {string} [date] Record date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAttendances(srvId: number, date?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Attendance>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAttendances(srvId, date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Signing in/out or absent for a child session
         * @param {number} srvId Service ID
         * @param {number} sessionId Session ID
         * @param {AttSignPayload} attSignPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAttSign(srvId: number, sessionId: number, attSignPayload: AttSignPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Session>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAttSign(srvId, sessionId, attSignPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Submit attendance headcount
         * @param {number} srvId Service ID
         * @param {SessionsHeadcountPayload} sessionsHeadcountPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postHeadcount(srvId: number, sessionsHeadcountPayload: SessionsHeadcountPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SessionsHeadcountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postHeadcount(srvId, sessionsHeadcountPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AttendanceApi - factory interface
 * @export
 */
export const AttendanceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AttendanceApiFp(configuration)
    return {
        /**
         * 
         * @summary Get daily session attendance record
         * @param {number} srvId Service ID
         * @param {string} [date] Record date
         * @param {string} [fdcEducatorId] FDC educator ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAttRecord(srvId: number, date?: string, fdcEducatorId?: string, options?: any): AxiosPromise<DailySession> {
            return localVarFp.getAttRecord(srvId, date, fdcEducatorId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get end of week attendance report
         * @param {number} srvId Service ID
         * @param {string} [date] Record date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAttendances(srvId: number, date?: string, options?: any): AxiosPromise<Array<Attendance>> {
            return localVarFp.getAttendances(srvId, date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Signing in/out or absent for a child session
         * @param {number} srvId Service ID
         * @param {number} sessionId Session ID
         * @param {AttSignPayload} attSignPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAttSign(srvId: number, sessionId: number, attSignPayload: AttSignPayload, options?: any): AxiosPromise<Session> {
            return localVarFp.postAttSign(srvId, sessionId, attSignPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Submit attendance headcount
         * @param {number} srvId Service ID
         * @param {SessionsHeadcountPayload} sessionsHeadcountPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postHeadcount(srvId: number, sessionsHeadcountPayload: SessionsHeadcountPayload, options?: any): AxiosPromise<SessionsHeadcountResponse> {
            return localVarFp.postHeadcount(srvId, sessionsHeadcountPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AttendanceApi - object-oriented interface
 * @export
 * @class AttendanceApi
 * @extends {BaseAPI}
 */
export class AttendanceApi extends BaseAPI {
    /**
     * 
     * @summary Get daily session attendance record
     * @param {number} srvId Service ID
     * @param {string} [date] Record date
     * @param {string} [fdcEducatorId] FDC educator ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttendanceApi
     */
    public getAttRecord(srvId: number, date?: string, fdcEducatorId?: string, options?: AxiosRequestConfig) {
        return AttendanceApiFp(this.configuration).getAttRecord(srvId, date, fdcEducatorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get end of week attendance report
     * @param {number} srvId Service ID
     * @param {string} [date] Record date
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttendanceApi
     */
    public getAttendances(srvId: number, date?: string, options?: AxiosRequestConfig) {
        return AttendanceApiFp(this.configuration).getAttendances(srvId, date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Signing in/out or absent for a child session
     * @param {number} srvId Service ID
     * @param {number} sessionId Session ID
     * @param {AttSignPayload} attSignPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttendanceApi
     */
    public postAttSign(srvId: number, sessionId: number, attSignPayload: AttSignPayload, options?: AxiosRequestConfig) {
        return AttendanceApiFp(this.configuration).postAttSign(srvId, sessionId, attSignPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Submit attendance headcount
     * @param {number} srvId Service ID
     * @param {SessionsHeadcountPayload} sessionsHeadcountPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttendanceApi
     */
    public postHeadcount(srvId: number, sessionsHeadcountPayload: SessionsHeadcountPayload, options?: AxiosRequestConfig) {
        return AttendanceApiFp(this.configuration).postHeadcount(srvId, sessionsHeadcountPayload, options).then((request) => request(this.axios, this.basePath));
    }
}
