import { createTheme } from '@mui/material/styles'

export const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#0098DA',
      light: '#CDF0FA',
    },
    secondary: {
      main: '#002CDA',
    },
    background: {
      default: '#f5f5f5',
      paper: '#fdfdfd',
    },
    success: {
      main: '#000000',
    },
    error: {
      main: '#ff0000',
    },
    // warning: {
    //   main: '#000000',
    // },
    info: {
      main: '#000000',
    },
    text: {
      primary: '#006a98',
    },
  },
  typography: {
    fontFamily: `'Poppins'`,
    body1: {
      fontFamily: `'Roboto'`,
    },
    button: {
      fontFamily: `'Roboto'`,
    },
    caption: {
      fontFamily: `'Roboto'`,
    },
    body2: {
      fontFamily: `'Roboto'`,
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiDialog: {
      styleOverrides: {
        container: {
          backdropFilter: 'blur(5px)',
        },
      },
    },
    MuiBottomNavigation: {
      styleOverrides: {
        root: {
          backgroundColor: '#f5f5f5',
        },
      },
    },
  },
})
