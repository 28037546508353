/* tslint:disable */
/* eslint-disable */
/**
 * Smart Central - FDC
 * Smart Central - FDC API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const KioskModes = {
    Code: 'code',
    Qr: 'qr',
    QrAndCode: 'qr_and_code'
} as const;

export type KioskModes = typeof KioskModes[keyof typeof KioskModes];



