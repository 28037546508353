import { ReactElement, ReactNode, useEffect, useState } from 'react'
import { NextPage } from 'next'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import { Session } from 'next-auth'
import { SessionProvider } from 'next-auth/react'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import { theme } from 'common/utils/theme'
import AlertSnackbar, { IAlert } from 'common/components/alert-snackbar'
import EventEmitter, { EVENT_ALERT } from 'common/utils/event-emitter'
import ApiProviderContext from 'common/context/api-provider-context'
import { AppContext } from 'common/context/app-context'

type NextAuthSession = { session: Session }
type AppPropsWithLayout = AppProps<NextAuthSession> & {
  Component: NextPageWithLayout
}

export type NextPageWithLayout = NextPage<NextAuthSession> & {
  getLayout?: (page: ReactElement) => ReactNode
}

function FDCApp({ Component, pageProps }: AppPropsWithLayout) {
  const [alert, setAlert] = useState<IAlert>({} as IAlert)

  useEffect(() => {
    EventEmitter.addListener(EVENT_ALERT, (alt: IAlert) =>
      setAlert({ ...alt, open: true })
    )
    return () => EventEmitter.removeAllListeners(EVENT_ALERT)
  }, [])

  return (
    <div>
      <Head>
        <title>FDC - SmartCentral</title>
        <meta name='fdc' content='fdc' />
        <link rel='icon' type='images/png' href='/favicon.png' />
      </Head>

      <ThemeProvider theme={theme}>
        <CssBaseline />

        <AlertSnackbar
          open={alert.open}
          type={alert.type}
          message={alert.message}
          onClose={() => setAlert({ ...alert, open: false, message: '' })}
        />

        <SessionProvider session={pageProps.session} refetchInterval={1800}>
          <ApiProviderContext>
            <AppContext>
              {Component.getLayout ? (
                Component.getLayout(<Component {...pageProps} />)
              ) : (
                <Component {...pageProps} />
              )}
            </AppContext>
          </ApiProviderContext>
        </SessionProvider>
      </ThemeProvider>
    </div>
  )
}

export default FDCApp
