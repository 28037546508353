import { useRouter } from 'next/router'
import { useGQLClient } from 'common/hooks/use-gql-client'

export const useGqlController = () => {
  const { query, isReady: isRouterReady, push } = useRouter()
  const { gqlClient, isClientReady } = useGQLClient()

  return {
    isReady: isRouterReady && isClientReady,
    gqlClient,
    query,
    push,
  }
}
