/* tslint:disable */
/* eslint-disable */
/**
 * Smart Central - FDC
 * Smart Central - FDC API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DeleteSessionMiscFee200Response } from '../models';
// @ts-ignore
import { MiscFee } from '../models';
// @ts-ignore
import { MiscFeePayload } from '../models';
// @ts-ignore
import { MiscFeeRecord } from '../models';
/**
 * MiscFeesApi - axios parameter creator
 * @export
 */
export const MiscFeesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete a misc fee from the session
         * @param {number} srvId Service ID
         * @param {number} sessionId Session ID
         * @param {number} miscFeeId Misc Fee ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSessionMiscFee: async (srvId: number, sessionId: number, miscFeeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'srvId' is not null or undefined
            assertParamExists('deleteSessionMiscFee', 'srvId', srvId)
            // verify required parameter 'sessionId' is not null or undefined
            assertParamExists('deleteSessionMiscFee', 'sessionId', sessionId)
            // verify required parameter 'miscFeeId' is not null or undefined
            assertParamExists('deleteSessionMiscFee', 'miscFeeId', miscFeeId)
            const localVarPath = `/service/{srv_id}/daily_session/delete_misc_fee/{session_id}/{misc_fee_id}.json`
                .replace(`{${"srv_id"}}`, encodeURIComponent(String(srvId)))
                .replace(`{${"session_id"}}`, encodeURIComponent(String(sessionId)))
                .replace(`{${"misc_fee_id"}}`, encodeURIComponent(String(miscFeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get miscellaneous fees record
         * @param {number} orgId Organisation ID
         * @param {string} [fdcEducatorId] FDC educator ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMiscFeesRecord: async (orgId: number, fdcEducatorId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgId' is not null or undefined
            assertParamExists('getMiscFeesRecord', 'orgId', orgId)
            const localVarPath = `/user/misc_fee_prices/{org_id}.json`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fdcEducatorId !== undefined) {
                localVarQueryParameter['fdc_educator_id'] = fdcEducatorId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add misc fee to daily session
         * @param {number} srvId Service ID
         * @param {number} sessionId Session ID
         * @param {MiscFeePayload} miscFeePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSessionMiscFee: async (srvId: number, sessionId: number, miscFeePayload: MiscFeePayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'srvId' is not null or undefined
            assertParamExists('postSessionMiscFee', 'srvId', srvId)
            // verify required parameter 'sessionId' is not null or undefined
            assertParamExists('postSessionMiscFee', 'sessionId', sessionId)
            // verify required parameter 'miscFeePayload' is not null or undefined
            assertParamExists('postSessionMiscFee', 'miscFeePayload', miscFeePayload)
            const localVarPath = `/service/{srv_id}/daily_session/create_misc_fee/{session_id}.json`
                .replace(`{${"srv_id"}}`, encodeURIComponent(String(srvId)))
                .replace(`{${"session_id"}}`, encodeURIComponent(String(sessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(miscFeePayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MiscFeesApi - functional programming interface
 * @export
 */
export const MiscFeesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MiscFeesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete a misc fee from the session
         * @param {number} srvId Service ID
         * @param {number} sessionId Session ID
         * @param {number} miscFeeId Misc Fee ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSessionMiscFee(srvId: number, sessionId: number, miscFeeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteSessionMiscFee200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSessionMiscFee(srvId, sessionId, miscFeeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get miscellaneous fees record
         * @param {number} orgId Organisation ID
         * @param {string} [fdcEducatorId] FDC educator ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMiscFeesRecord(orgId: number, fdcEducatorId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MiscFeeRecord>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMiscFeesRecord(orgId, fdcEducatorId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add misc fee to daily session
         * @param {number} srvId Service ID
         * @param {number} sessionId Session ID
         * @param {MiscFeePayload} miscFeePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postSessionMiscFee(srvId: number, sessionId: number, miscFeePayload: MiscFeePayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MiscFee>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postSessionMiscFee(srvId, sessionId, miscFeePayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MiscFeesApi - factory interface
 * @export
 */
export const MiscFeesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MiscFeesApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete a misc fee from the session
         * @param {number} srvId Service ID
         * @param {number} sessionId Session ID
         * @param {number} miscFeeId Misc Fee ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSessionMiscFee(srvId: number, sessionId: number, miscFeeId: number, options?: any): AxiosPromise<DeleteSessionMiscFee200Response> {
            return localVarFp.deleteSessionMiscFee(srvId, sessionId, miscFeeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get miscellaneous fees record
         * @param {number} orgId Organisation ID
         * @param {string} [fdcEducatorId] FDC educator ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMiscFeesRecord(orgId: number, fdcEducatorId?: string, options?: any): AxiosPromise<Array<MiscFeeRecord>> {
            return localVarFp.getMiscFeesRecord(orgId, fdcEducatorId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add misc fee to daily session
         * @param {number} srvId Service ID
         * @param {number} sessionId Session ID
         * @param {MiscFeePayload} miscFeePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSessionMiscFee(srvId: number, sessionId: number, miscFeePayload: MiscFeePayload, options?: any): AxiosPromise<MiscFee> {
            return localVarFp.postSessionMiscFee(srvId, sessionId, miscFeePayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MiscFeesApi - object-oriented interface
 * @export
 * @class MiscFeesApi
 * @extends {BaseAPI}
 */
export class MiscFeesApi extends BaseAPI {
    /**
     * 
     * @summary Delete a misc fee from the session
     * @param {number} srvId Service ID
     * @param {number} sessionId Session ID
     * @param {number} miscFeeId Misc Fee ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MiscFeesApi
     */
    public deleteSessionMiscFee(srvId: number, sessionId: number, miscFeeId: number, options?: AxiosRequestConfig) {
        return MiscFeesApiFp(this.configuration).deleteSessionMiscFee(srvId, sessionId, miscFeeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get miscellaneous fees record
     * @param {number} orgId Organisation ID
     * @param {string} [fdcEducatorId] FDC educator ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MiscFeesApi
     */
    public getMiscFeesRecord(orgId: number, fdcEducatorId?: string, options?: AxiosRequestConfig) {
        return MiscFeesApiFp(this.configuration).getMiscFeesRecord(orgId, fdcEducatorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add misc fee to daily session
     * @param {number} srvId Service ID
     * @param {number} sessionId Session ID
     * @param {MiscFeePayload} miscFeePayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MiscFeesApi
     */
    public postSessionMiscFee(srvId: number, sessionId: number, miscFeePayload: MiscFeePayload, options?: AxiosRequestConfig) {
        return MiscFeesApiFp(this.configuration).postSessionMiscFee(srvId, sessionId, miscFeePayload, options).then((request) => request(this.axios, this.basePath));
    }
}
